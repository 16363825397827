// ==========================================================================
// Settings
// ==========================================================================

$output-bourbon-deprecation-warnings: false;

// Fonts
// ==========================================================================

// @include font-face( 'GearedSlab', '../fonts/GearedSlab-Regular', 300, normal, '', woff2 );

$ff-default : sans-serif;

$fs-default-px : 16px;
$fs-default-mobile-px : 16px;
$fw-default: 400;

$line-height-default : 1.625em;

// Use to calculate "px to rem" with rem() function
$em-base : $fs-default-px;


// Colors
// ==========================================================================

$color-primary      : #FF4849;

$body-background : white;


// Greys
// ==========================================================================

$color-grey-00  : #ffffff;
$color-grey-05  : #efefef;
$color-grey-10  : #ccd2d7;
$color-grey-40  : #B3B4AA;
$color-grey-50  : #68665e;
$color-grey-60  : #464446;
$color-grey-65  : #2f3033;
$color-grey-70  : #2d2d33;
$color-grey-80  : #1d1d22;
$color-grey-90  : #151618;
$color-grey-100 : #000000;

$body-background : white;


// Global Metrics
// ==========================================================================

$container-width          : rem( 1800px );
$container-padding        : rem( 140px );
$container-padding-mobile : rem( 15px );


// Misc
// ==========================================================================

$path-assets : '/assets';