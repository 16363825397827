nav.navbar {
  padding: 1rem 2rem;

  background-color: black;

  .navbar-brand {
    margin-right: 4rem;
  }

  .navbar-nav {
    li {

      &:before {
        content: "\2022";
        color: #333;
        display: inline-block;
        padding: 0 17px;
        height: 60px;
        line-height: 60px;
      }

      .nav-link {
        display: inline-block;
      }
    }
  }
}