.input-group {
    .select2-container {
        flex-grow: 1;

        .select2-selection {
            display: flex;
            align-items: center;

            height: 100%;

            border-radius: 0 4px 4px 0;
            border: 1px solid #ced4da;

            .select2-selection__arrow {
                top: calc(50% - 13px);
            }
        }
    }


    .selectize-control {
        flex-grow: 1;
        width: initial !important;

        .selectize-input {
            display: flex;
            align-items: center;

            height: 100%;

            border-radius: 0 4px 4px 0;
            border: 1px solid #ced4da;

            &.input-active {
                display: flex;
            }
        }
    }
}