@mixin ellipsis-multiline( $font-size, $line-height, $lines-to-show ){
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 100%;
    max-height: $font-size * strip-units( $line-height ) * $lines-to-show; /* Fallback for non-webkit */
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size;
    line-height: strip-units( $line-height ) + em;
}

@mixin reset-ellipsis() {
    max-height: none;
    height: auto;
    display: block;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    overflow: initial;
    text-overflow: initial;
}

@mixin retina-name( $name) {
    @include retina-image($name , 32px 32px, $retina-suffix: '@2x');
}


@mixin mobile() {
    @media screen and ( max-width: 576px ) {
        @content;
    }
}

@mixin tablet() {
    @media screen and ( max-width: 768px ) {
        @content;
    }
}

@mixin smalldesktop() {
    @media screen and ( max-width: 992px ) {
        @content;
    }
}

@mixin desktop() {
    @media screen and ( min-width: 992px ) {
        @content;
    }
}

@function img( $name ) {
    @return url( $path-assets + '/img/' + $name );
}

@function svg( $name ) {
    @return url( $path-assets + '/svg/' + $name );
}

// IE9+ CSS
@mixin iehacks() {
    @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
      // IE9+ CSS
      @content
    }
}