#map {
    height: 100%;
    min-height: 25rem;
    margin-bottom: 30px;
}

@include smalldesktop {
    #map {
        height: 20rem;
    }
}