// ==========================================================================
// Class helpers
// ==========================================================================

//
// Display
// ==========================================================================

.hidden {
    display: none;
    visibility: 0;
}

//
// Vertical alignement
// ==========================================================================

.valign-helper,
.valign-item,
.valign-container:before {
    display: inline-block;
    vertical-align: middle;
}

.valign-helper { height: 100%; }
.valign-item { max-width: 100%; }

.valign-container:before {
    content: '';
    height: 100%;
    width: 0;
    position: relative;
}


//
// Responsive
// ==========================================================================

@include mobile {
    .hide-mobile { display: none !important; }
}

@include tablet {
    .hide-tablet { display: none !important; }
    .on-desktop { display: none !important; }
}

.is-touch .on-desktop { display: none !important; }

@include desktop {
    .hide-desktop { display: none !important; }
    body:not( .is-touch ) .on-mobile { display: none !important; }
}
