// ==========================================================================
// Global styles
// ==========================================================================

html, body {
    //
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    max-width: 1600px; /* or 950px */

    > nav {
        margin: 2rem 0;
    }
}

.table td, .table th {
    vertical-align: middle;
}

.table td.td-fit,
.table th.td-fit {
    white-space: nowrap;
    width: 1%;
}

@include tablet {
    .container-fluid {
        > nav {
            margin: 1rem 0;
        }
    }
}

